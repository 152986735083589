<template>
 <div>
  <header-style title="Settings"/>
  <div class="setting-table mt-5">
    <div class="d-flex justify-content-between py-2">
    <h5 class="px-3" style="font-weight:bold;font-size: 20px;">Payment Gateways</h5>
    <b-button variant="green-dark" class="mx-3 px-2" style="width:201px;height: 36px; border-radius: 4px;"><i class="fas fa-plus"></i> Add Payment Gateway</b-button>
  </div>
    <main-table
        :list_url="'gateway_providers'"
        :fields="fields"
        :items="items"
      />
  </div>
  <div class="setting-table mt-5">
    <div class="d-flex justify-content-between py-2">
    <h5 class="px-3" style="font-weight:bold;font-size: 20px;">Payment Methods</h5>
    <b-button variant="green-dark" class="mx-3 px-2" style="width:201px;height: 36px; border-radius: 4px;"><i class="fas fa-plus"></i> Add Payment Method</b-button>
  </div>
    <main-table
        :fields="fields2"
        :items="items2"
        :list_url="'payment_methods'"
      />
  </div>
 </div>
</template>

<script>
import HeaderStyle from '../../components/core/partials/HeaderStyle/HeaderStyle.vue'
export default {
  components: { HeaderStyle },
  data () {
    return {
      fields: [
        { key: 'title', label: 'Payment Gateway', sortable: true },
        { key: 'created_at', label: 'Joined Date', sortable: true },
        {
          key: 'actions',
          label: this.$t('table.actions'),
          type: 'actions',
          actions: [
            {
              text: this.$t('global.edit'),
              icon: 'fas fa-pen',
              bgcolor: 'transparent',
              color: '',
              ifNavigate: true,
              routeName: 'clients.clientView',
              routeParams: ['id']
            },
            {
              text: '',
              icon: 'fas fa-trash',
              bgcolor: 'dangerlight',
              color: '',
              ifNavigate: true,
              routeName: 'clients.clientView',
              routeParams: ['id']
            }
            // {
            //   text: this.$t(''),
            //   icon: 'fas fa-user-lock',
            //   color: '',
            //   ifNavigate: true,
            //   routeName: 'clients.clientView',
            //   routeParams: ['id']
            // }
          ]
        }
      ],
      // items: [
      //   { PaymentGateway: 'Noon', joinedDate: '11-05-2022, 5:30 PM' },
      //   { PaymentGateway: 'Fawry', joinedDate: '11-05-2022, 5:30 PM' },
      //   { PaymentGateway: 'PayMob', joinedDate: '11-05-2022, 5:30 PM' }
      // ],
      fields2: [
        { key: 'title', label: 'Payment Method', sortable: true },
        { key: 'created_at', label: 'Joined Date', sortable: true },
        {
          key: 'actions',
          label: this.$t('table.actions'),
          type: 'actions',
          actions: [
            {
              text: this.$t('global.edit'),
              icon: 'fas fa-pen',
              bgcolor: 'transparent',
              color: '',
              ifNavigate: true,
              routeName: 'clients.clientView',
              routeParams: ['id']
            },
            {
              text: '',
              icon: 'fas fa-trash',
              bgcolor: 'dangerlight',
              color: '',
              ifNavigate: true,
              routeName: 'clients.clientView',
              routeParams: ['id']
            }
            // {
            //   text: this.$t(''),
            //   icon: 'fas fa-user-lock',
            //   color: '',
            //   ifNavigate: true,
            //   routeName: 'clients.clientView',
            //   routeParams: ['id']
            // }
          ]
        }
      ],
      // items2: [
      //   { id: '20', PaymentMethod: 'Visa', joinedDate: '11-05-2022, 5:30 PM' },
      //   { id: '21', PaymentMethod: 'Card', joinedDate: '11-05-2022, 5:30 PM' },
      //   { id: '22', PaymentMethod: 'Cash', joinedDate: '11-05-2022, 5:30 PM' }
      // ],
      rows: 100,
      perPage: 1,
      currentPage: 1
    }
  }
}
</script>

<style>
.setting-table{
  background-color: white;
  /* box-shadow: 0 4px 12px 2px rgba(102, 102, 102, 0.16); */
  padding: 0px;
 border-radius: 8px;
 margin-inline-end: 20px;
 margin-inline-start: 10px;
}

</style>
