import { mapActions } from 'vuex'

export default {
  // data () {
  //   return {
  //     rtl: false,
  //     dark: false
  //   }
  // },
  methods: {
    langChange (lang) {
      this.langChangeState(lang)
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
    },
    ...mapActions({
      langChangeState: 'Setting/setLangAction'
    })
  }
}
